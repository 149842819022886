import React, { useEffect, useState } from "react";
// import logo from '../assets/images/logo.jpg';
import Tooglebutton from "./ToogleButton/ToggleButton";
import { useSelector } from "react-redux";
// import ToggleButton from "../components/ToogleButton/ToggleButton";
import darkLogo from "../assets/images/darkLogo.png";
import lightLogo from "../assets/images/lightLogo.png";


function Navbar({ onSelct }) {
    const [isActive, setIsActive] = useState(false);
    const [darkMode, setDarkMode] = useState(true);
    const dark = useSelector((state) => state.darkmode.darkmode);

    useEffect(() => {
        setDarkMode(dark);
    }, [dark])

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    // useEffect(() => { onSelct(darkMode); }, [darkMode])

    return (
        <header className="header" data-header="">
            <div className="container">
                <div className={`overlay ${isActive ? 'active' : ''}`} data-overlay="" onClick={toggleMenu} />
                <a href="/" className="logo" >
                    {/* <img height={70} width={70} src={logo} alt="Eduland logo" /> */}
                    {/* <h1 style={{ color: darkMode?"white":'black' }}>GDCS</h1> */}
                    <img src={dark? darkLogo : lightLogo} style={{display:'flex',justifyContent:'center',alignItems: 'center',}}/>
                </a>
                <button className="menu-open-btn" data-menu-open-btn="" onClick={toggleMenu}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 12H21M3 6H21M3 18H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <nav className={`navbar ${isActive ? 'active' : ''}`} data-navbar="">
                    <button className="menu-close-btn" data-menu-close-btn="" onClick={toggleMenu}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    <a href="#" className="logo">
                     <img src={darkLogo}/>
                    </a>
                    <ul className="navbar-list">
                        <li>
                            <a href="/" className="navbar-link">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="/#about" className="navbar-link">
                                About
                            </a>
                        </li>
                        <li>
                            <a href="/#internship" className="navbar-link">
                                Internship
                            </a>
                        </li>
                        <li>
                            <a href="/#blog" className="navbar-link">
                                Popular Cource
                            </a>
                        </li>
                        <li>
                            <a href="/enquiry" className="navbar-link">
                                Enquiry
                            </a>
                        </li>
                        <li>
                            <Tooglebutton />
                        </li>
                    </ul>
                    <a href="/enquiry">
                        <button className="btn btn-secondary">Get Started</button>
                    </a>
                </nav>
            </div>
        </header>
    );
}

export default Navbar;