import React from "react";
import "./ProjectCategory.css";

const ProjectCategory = ({ id }) => {
  return (
    <section id={id} class="section_Pcat category_Pcat" aria-label="category">
      <div class="container_Pcat">
        <p class="section-subtitle_Pcat">Project Cources</p>

        <h2 class="h2_Pcat section-title_Pcat">
          Online Classes For Remote Learning
        </h2>

        <ul class="grid-list_Pcat">
          <li>
            <div
              class="category-card_Pcat"
              style={{
                backgroundColor: "hsla(170, 75%, 41%,0.1)",
                borderRadius: "10px",
              }}
            >
              <div class="card-icon_Pcat">
                <img
                  src="./assets/images/category-1.svg"
                  width="40"
                  height="40"
                  loading="lazy"
                  alt="Online Degree Programs"
                  class="img"
                />
              </div>

              <h3 class="h3_Pcat">
                <a href="#" class="card-title_Pcat">
                  React native UI/UX Course 
                </a>
              </h3>

              <p class="card-text_Pcat">
               Coming Soon
              </p>

              {/* <span class="card-badge_Pcat">7 Courses</span> */}
            </div>
          </li>

          <li>
            <div
              class="category-card_Pcat"
              style={{
                backgroundColor: "hsla(351, 83%, 61%,0.1)",
                borderRadius: "10px",
              }}
            >
              <div class="card-icon_Pcat">
                <img
                  src="./assets/images/category-1.svg"
                  width="40"
                  height="40"
                  loading="lazy"
                  alt="Online Degree Programs"
                  class="img"
                />
              </div>

              <h3 class="h3_Pcat">
                <a href="#" class="card-title_Pcat">
                React native Backend Course 
                </a>
              </h3>

              <p class="card-text_Pcat">
               Coming soon
              </p>

              {/* <span class="card-badge_Pcat">7 Courses</span> */}
            </div>
          </li>

          <li>
            <div
              class="category-card_Pcat"
              style={{
                backgroundColor: "hsla(229, 75%, 58%,0.1)",
                borderRadius: "10px",
              }}
            >
              <div class="card-icon_Pcat">
                <img
                  src="./assets/images/category-1.svg"
                  width="40"
                  height="40"
                  loading="lazy"
                  alt="Online Degree Programs"
                  class="img"
                />
              </div>

              <h3 class="h3_Pcat">
                <a href="#" class="card-title_Pcat">
                  Firebase Full course
                </a>
              </h3>

              <p class="card-text_Pcat">
                Coming soon
              </p>

              {/* <span class="card-badge_Pcat">7 Courses</span> */}
            </div>
          </li>

          <li>
            <div
              class="category-card_Pcat"
              style={{
                backgroundColor: "hsla(42, 94%, 55%,0.1)",
                borderRadius: "10px",
              }}
            >
              <div class="card-icon_Pcat">
                <img
                  src="./assets/images/category-1.svg"
                  width="40"
                  height="40"
                  loading="lazy"
                  alt="Online Degree Programs"
                  class="img"
                />
              </div>

              <h3 class="h3_Pcat">
                <a href="#" class="card-title_Pcat">
                  Java Full Stack Course
                </a>
              </h3>

              <p class="card-text_Pcat">
               Coming Soon
              </p>

              {/* <span class="card-badge_Pcat">7 Courses</span> */}
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ProjectCategory;
