import React from "react";
import './ToggleButton.css';
import { useSelector, useDispatch } from 'react-redux'
import { setDarkmode } from "../../features/dark/dark";

const Tooglebutton = () => {


    const dispatch = useDispatch();
    const dark = useSelector((state) => state.darkmode.darkmode);

    return (
        <label class="switch">
            
            <input checked={!dark} onSelect={() => dispatch(setDarkmode(!dark))} onClick={() => dispatch(setDarkmode(!dark))} type="checkbox" />
            <span class="slider"></span>
        </label>
    );
}
export default Tooglebutton;