import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebaseconfig";
import SkeletonTypography from "./Loading/SkeletonTypography";


const CategoryCard = ({ cat }) => {

    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchCategories = async (category) => {
        try {
            const querySnapshot = await getDoc(doc(db, "categories", category));
            const categories = [];
            // querySnapshot.forEach((doc) => {
            categories.push({ id: querySnapshot.id, ...querySnapshot.data() });
            // });
            return categories;
        } catch (error) {
            console.error("Error fetching categories: ", error);
            throw error;
        }
    };

    useEffect(() => {
        const getCategories = async () => {
            try {
                setLoading(true);
                const categoriesList = await fetchCategories(cat);
                setCategory(categoriesList);
                setLoading(false);
            } catch (error) {
                console.error("Error loading categories: ", error);
            }
        };

        getCategories();
    }, [cat]
    );

    if (loading) {
        return (
            <SkeletonTypography />
        );
    }

    return (
        <>
            {category.map((item) => (

                <div className="area">
                    <h1 className="name-area">
                        <span>{item.title}</span> Courses
                    </h1>
                    <h2 className="name-area-subtitle">Courses to get you started</h2>
                    <h2 className="name-area-subtitle subtitle-fad">
                        Explore courses from experienced, real-world experts.
                    </h2>
                </div>
            ))}
        </>
    );
}

export default CategoryCard;