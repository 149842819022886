import React, { useEffect, useState } from "react";
import "./ContactPage.css";
import Lottie from 'react-lottie';
import ContactGif from '../../assets/images/Contact.json';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebaseconfig";
const FormCreate = () => {
  const [category, setCategory] = useState([]);
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    contactNumber: "",
    email: "",
    message: "",
  });

  const fetchCategories = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "categories"));
      const categories = [];
      querySnapshot.forEach((doc) => {
        categories.push({ id: doc.id, ...doc.data() });
      });
      return categories;
    } catch (error) {
      console.error("Error fetching categories: ", error);
      throw error;
    }
  };

  useEffect(() => {
    const getCategories = async () => {
      try {
        const categoriesList = await fetchCategories();
        setCategory(categoriesList);
        console.log(categoriesList);
      } catch (error) {
        console.error("Error loading categories: ", error);
      }
    };

    getCategories();
  }, []
  )

  const [errors, setErrors] = useState({});

  const registrationNoGenerator = () => {
    const year = new Date().getFullYear().toString().slice(2);
    const mth = (new Date().getMonth() + 1).toLocaleString().padStart(2, "0");
  };
  const validateForm = () => {
    const newErrors = {};
    if (!formData.fname) newErrors.name = "Name is required";
    if (!formData.lname) newErrors.name = "Name is required";
    if (!formData.contactNumber) {
      newErrors.contactNumber = "Contact Number is required";
    } else if (!/^\d{10}$/.test(formData.contactNumber)) {
      newErrors.contactNumber = "Contact Number must be a 10-digit number";
    }
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.message) newErrors.message = "Message is Required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {

    }
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ContactGif,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <section className="Contact_section">
      <div className="Contact_container">
        <div className="Contact_splitArea">
          <div className="Conatct_Adv">
            <Lottie options={defaultOptions} height={'100%'} width={'100%'} />
          </div>
          <div className="Contact_Form">
            <div style={{ width: '70%' }}>
              <h1>GDCS</h1>

            </div>
            <div className="Contact_UpperQuote">
              <h2>We'd love to help!</h2>
              <p>
                We're a full service agency with experts ready to help. We'll get in touch within 24 hours.
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div >
                <div className="Contact_NameArea">
                  <div className="Contact_FirstName">
                    <label>First name</label>
                    <input required name="fname" value={formData.fname} onChange={handleChange} placeholder="First name" inputMode="text" />
                  </div>
                  <div className="Contact_LastName">
                    <label>Last name</label>
                    <input required name="lname" value={formData.lname} onChange={handleChange} placeholder="Last name" inputMode="text" />
                  </div>
                </div>
                <div className="Contact_EmailArea">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label>Email</label>
                    <input required name="email" onChange={handleChange} value={formData.email} placeholder="someone@mail.com" inputMode="email" type="email" />
                  </div>
                </div>
                <div className="Contact_PhoneArea">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label>Phone number</label>
                    <input required name="contactNumber" value={formData.contactNumber} onChange={handleChange} placeholder="789456....." inputMode="tel" type="number" />
                  </div>
                </div>
                <div className="Contact_MessageArea">
                  <div>
                    <label>Message</label>
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      cols="80"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      error={!!errors.message}
                    />
                  </div>
                </div>

                    <div>
                      <select>
                        <option>Select</option>
                        {category.map((item) => (
                          <option value={item.id}>{item.title}</option>
                        ))}
                      </select>
                    </div>
              </div>
              <button
                type="submit"
                title="Submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FormCreate;
