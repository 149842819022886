import React, { useEffect, useState } from "react";
import './mainCategory.css';
import { collection, doc, getDocs } from "firebase/firestore";
import { db } from "../../firebaseconfig";
import CategoryCoursesCount from "../CourseCount";
import { Link } from "react-router-dom";

const Category = ({ id }) => {
    const [category, setCategory] = useState([]);

    const fetchCategories = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "categories"));
            const categories = [];
            querySnapshot.forEach((doc) => {
                categories.push({ id: doc.id, ...doc.data() });
            });
            return categories;
        } catch (error) {
            console.error("Error fetching categories: ", error);
            throw error;
        }
    };

    useEffect(() => {
        const getCategories = async () => {
            try {
                const categoriesList = await fetchCategories();
                setCategory(categoriesList);
            } catch (error) {
                console.error("Error loading categories: ", error);
            }
        };

        getCategories();
    }, []
    )

    return (
        <section className="sectionMcat categoryMcat" aria-label="category" id={id}>
            <div className="container_mainCat">
                <p className="section-subtitle">Internship Course</p>
                <h2 className="h2 section-title">Popular Topics To Learn</h2>
                <ul className="grid-list">
                    {category.map((category) => (
                        <li key={category.id}>
                            <Link to={"/courses/" + category.id}>
                                <div className="category-card">
                                    <div className="card-icon">
                                        <img height={20} width={20} src={category.imageUrl} />
                                    </div>
                                    <div>
                                        <h3 className="h3 card-title">
                                            <a href="#">{category.title}</a>
                                        </h3>
                                        <span className="card-meta">
                                            <CategoryCoursesCount categoryId={category.id} />
                                        </span>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
}

export default Category;