import React, { useEffect, useState } from "react";
import "./PopularCourseList.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebaseconfig";


const PopularCourseList = ({ id }) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        let productData = [];

        // Fetch products by category
        const snapshot = await getDocs(collection(db, "BestCourses"));
        productData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          // timestamp: doc.data().timestamp.toDate().toDateLocalString(), // Convert Firestore timestamp to Date and format it to MM/DD/YYYY
        }));
        setData(productData);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };
    fetchProduct();
  }, [])

  return (
    <section
      className="section_Pop_CourseList blog has-bg-image_Pop"
      id={id}
      aria-label="blog"
      // style={{ backgroundImage: 'url("https://raw.githubusercontent.com/codewithsadee/eduweb/e95ce3bd395db274166c4d8abe7970b699a4c39d/assets/images/blog-bg.svg")' }}
    >
      <div className="container_Pop">
        <p className="section-subtitle_pop">Popular Internship Course</p>
I        <h2 className="h2_Pop section-title_Pop">Get News With Eduweb</h2>
        <ul className="grid-list_Pop">
          {data.map((item) => (

          <li key={item.id}>
            <div className="blog-card_Pop">
              <figure className="card-banner_Pop img-holder_Pop has-after_Pop">
                <img
                  src={item.imageUrl}
                  // width={370}
                  // height={370}
                  loading="lazy"
                  // alt="Become A Better Blogger: Content Planning"
                  className="img-cover_Pop_CourseList"
                
                  
                />
              </figure>
              <div className="card-content_Pop">
                <a href="#" className="card-btn_Pop" aria-label="read more">
                  {/* <ion-icon name="arrow-forward-outline" aria-hidden="true" /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 15 15"
                  >
                    <path
                      fill="currentColor"
                      d="M8.293 2.293a1 1 0 0 1 1.414 0l4.5 4.5a1 1 0 0 1 0 1.414l-4.5 4.5a1 1 0 0 1-1.414-1.414L11 8.5H1.5a1 1 0 0 1 0-2H11L8.293 3.707a1 1 0 0 1 0-1.414Z"
                    />
                  </svg>
                </a>
                {/* <a href="#" className="card-subtitle_Pop">
                  Online
                </a> */}
                <h3 className="h3_Pop">
                  <a href="#" className="card-title_Pop">
                    {item.title}
                  </a>
                </h3>
                <ul className="card-meta-list_Pop">
                  <li className="card-meta-item_Pop">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M19.5 4h-3V2.5a.5.5 0 0 0-1 0V4h-7V2.5a.5.5 0 0 0-1 0V4h-3A2.503 2.503 0 0 0 2 6.5v13A2.503 2.503 0 0 0 4.5 22h15a2.502 2.502 0 0 0 2.5-2.5v-13A2.502 2.502 0 0 0 19.5 4zM21 19.5a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5V11h18v8.5zm0-9.5H3V6.5C3 5.672 3.67 5 4.5 5h3v1.5a.5.5 0 0 0 1 0V5h7v1.5a.5.5 0 0 0 1 0V5h3A1.5 1.5 0 0 1 21 6.5V10z"
                      />
                    </svg>
                    <span className="span_Pop">Oct 10, 2021</span>
                  </li>
                  <li className="card-meta-item_Pop">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none">
                        <path
                          fill="currentColor"
                          d="m4.327 6.638l.891.454zm.441 13.594l-.707-.707zm13.594-3.559l.454.891zm1.311-1.311l.891.454zm0-8.724l.891-.454zm-1.311-1.311l.454-.891zm-12.724 0l.454.891zm2.07 11.966L7 16.586zM5 9.8c0-.857 0-1.439.038-1.889c.035-.438.1-.663.18-.819l-1.782-.908c-.247.485-.346 1.002-.392 1.564C3 8.298 3 8.976 3 9.8zM5 12V9.8H3V12zm-2 0v5h2v-5zm0 5v2.914h2V17zm0 2.914c0 1.291 1.562 1.938 2.475 1.025l-1.414-1.414a.55.55 0 0 1 .939.389zm2.475 1.025L8.415 18L7 16.586l-2.939 2.939zM15.2 16H8.414v2H15.2zm2.708-.218c-.156.08-.38.145-.819.18c-.45.037-1.032.038-1.889.038v2c.824 0 1.501 0 2.052-.044c.562-.046 1.079-.145 1.564-.392zm.874-.874a2 2 0 0 1-.874.874l.908 1.782a4 4 0 0 0 1.748-1.748zM19 12.2c0 .857 0 1.439-.038 1.889c-.035.438-.1.663-.18.819l1.782.908c.247-.485.346-1.002.392-1.564c.045-.55.044-1.228.044-2.052zm0-2.4v2.4h2V9.8zm-.218-2.708c.08.156.145.38.18.819C19 8.361 19 8.943 19 9.8h2c0-.824 0-1.501-.044-2.052c-.046-.562-.145-1.079-.392-1.564zm-.874-.874a2 2 0 0 1 .874.874l1.782-.908a4 4 0 0 0-1.748-1.748zM15.2 6c.857 0 1.439 0 1.889.038c.438.035.663.1.819.18l.908-1.782c-.485-.247-1.002-.346-1.564-.392C16.702 4 16.024 4 15.2 4zM8.8 6h6.4V4H8.8zm-2.708.218c.156-.08.38-.145.819-.18C7.361 6 7.943 6 8.8 6V4c-.824 0-1.501 0-2.052.044c-.562.046-1.079.145-1.564.392zm-.874.874a2 2 0 0 1 .874-.874l-.908-1.782a4 4 0 0 0-1.748 1.748zM8.414 18v-2A2 2 0 0 0 7 16.586z"
                        />
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 9h8m-8 4h5"
                        />
                      </g>
                    </svg>
                    <span className="span_Pop">Com 09</span>
                  </li>
                </ul>
                <p className="card-text_Pop">
                  Lorem Ipsum Dolor Sit Amet Cons Tetur Adipisicing Sed.
                </p>
              </div>
            </div>
          </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default PopularCourseList;
