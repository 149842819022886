import React, { useEffect } from "react";
import "./CourseList.css";
import CourseCard from "../../components/CourseCard/CourseCard";
import { useState } from "react";
import { db } from "../../firebaseconfig";
import { collection, getDocs } from "firebase/firestore";
import { useParams } from "react-router-dom";
import StudentEnroll from "../../components/StudentEnrolled/StudentEnrolled";
import Email from "../../components/Email/Email";
import PopularCourseList from "../../components/PoppularCourseList/PopularCourseList";
import CategoryCard from "../../components/CategoryCard";

export default function CourseList() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const { category } = useParams();
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const snapshot = await getDocs(collection(db, "categories"));
        const categoryData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoryData);

        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchCategories();
  }, []);

  const handleClick = (index, filter) => {
    setFilter(filter);
    setActiveIndex(index);
  };
  return (
    <main className="CLsec">
      <nav className="upperArea">
        <ul className="verticallistArea">
          <li className="listName mainCategory">
            <a>
              <span>Internship course</span>
            </a>
            <img alt="slash" src="https://s.udemycdn.com/browse_components/link-bar/large-next.svg" />
          </li>
          <div className="scrollArea">
            {categories.map((item, index) => (
              <li key={index} className="listName subCategory">
                <a href={`/courses/${item.id}`}>
                  <span>{item.title}</span>
                </a>
              </li>
            ))}
          </div>
        </ul>
      </nav>
      <CategoryCard cat={category} />

      <div className="course-list-area">
        <div className="categoryArea">
          <div style={{ display: "flex", borderBottom: '1px solid gray', marginBottom: '10px', }}>
            {["All", "New", "Trending"].map(
              (text, index) => (
                <span
                  key={index}
                  className={`category ${activeIndex === index ? "active" : ""
                    }`}
                  onClick={() => handleClick(index, text)}
                >
                  {text}
                </span>
              )
            )}
          </div>
          <div
           
            className="cardArea"
          >
            <CourseCard cat={category} filtering={filter} />
          </div>
        </div>
      </div>

      <PopularCourseList />
      <StudentEnroll />
      <Email />
    </main>
  );
}
