import React from "react";

const Privacy = ({ darkMode }) => {
    return (
        <>
            <body className={darkMode ? "" : 'dark'} style={{ marginTop: '60px' }}>
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            ' * {margin:0; padding:0; text-indent:0; }\n .s1 { color: #444; font-family:Arial, sans-serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 12pt; }\n h1 { color: #444; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 16.5pt; }\n .p, p { color: #444; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; margin:0pt; }\n h2 { color: #444; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 12pt; }\n li {display: block; }\n #l1 {padding-left: 0pt;counter-reset: c1 1; }\n #l1> li>*:first-child:before {counter-increment: c1; content: counter(c1, decimal)". "; color: #444; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; }\n #l1> li:first-child>*:first-child:before {counter-increment: c1 0;  }\n'
                    }}
                />
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p
                    className="s1"
                    style={{ paddingLeft: "32pt", textIndent: "0pt", textAlign: "left" }}
                >
                    This privacy policy was last updated on june, 2024
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <h1 style={{ paddingLeft: "32pt", textIndent: "0pt", textAlign: "left" }}>
                    Data Collection
                </h1>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p
                    style={{
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    We collect certain data from you directly, like the details you enter
                    yourself, data about your participation in course, registration, use of the
                    service site, communicating with site members, admin, using content, content
                    upload to our site and from third-party platforms you connect with College
                    planet. We also collect some data automatically, such as information about
                    your device and what part of the service you interact with or spend time
                    using.
                </p>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <ol id="l1">
                    <li data-list-text={1}>
                        <h2
                            style={{
                                paddingLeft: "32pt",
                                textIndent: "0pt",
                                lineHeight: "167%",
                                textAlign: "left"
                            }}
                        >
                            Personal Information provided by you:{" "}
                            <span className="p">
                                In some areas of our Services, request you to provide or submit
                                personal information including your name, address, e-mail address,
                                telephone number, contact information, billing information, education
                                details, work place details, and any other information from which your
                                identity is discernible.
                            </span>
                        </h2>
                    </li>
                    <li data-list-text={2}>
                        <h2
                            style={{
                                paddingLeft: "32pt",
                                textIndent: "0pt",
                                lineHeight: "165%",
                                textAlign: "left"
                            }}
                        >
                            Information collected when you use third party services:{" "}
                            <span className="p">
                                We may indirectly collect information about you when you use certain
                                third party services on our Services; such information will be subject
                                to, and treated in accordance with, the policies and practices
                                described in this Statement and can be used by College planet as it
                                deems fit. We may collect process and store your information
                                associated with your Google account if you choose to sign in using
                                Google. When you sign in to your account with your Google account
                                information, you consent to our collection, storage, and use of the
                                information that you make available to us through your Google account
                                in accordance with this Privacy Policy. This could include your name,
                                email address and phone number associated with your Google account.
                            </span>
                        </h2>
                    </li>
                    <li data-list-text={3}>
                        <h2
                            style={{
                                paddingLeft: "32pt",
                                textIndent: "0pt",
                                lineHeight: "166%",
                                textAlign: "left"
                            }}
                        >
                            Information collected through use of service:{" "}
                            <span className="p">
                                We also gather or may gather certain information about your use of our
                                Services, such as what areas you visit and what features you access.
                                Moreover, there is information about your computer hardware and
                                software that
                            </span>
                        </h2>
                    </li>
                </ol>
                <p
                    style={{
                        paddingTop: "4pt",
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "166%",
                        textAlign: "left"
                    }}
                >
                    is or may be collected by us. This information can include without
                    limitation your IP address, browser type, domain names, access times and
                    referring website addresses and can be used by College planet as it deems
                    fit.
                </p>
                <h1
                    style={{
                        paddingTop: "7pt",
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    Children’s Privacy
                </h1>
                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p
                    style={{
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    We are committed to protecting children’s privacy online. This Site is
                    intended for users above the age of 13. We do not knowingly collect payment
                    related information from children.
                </p>
                <h1
                    style={{
                        paddingTop: "10pt",
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    Payment &amp; Billing
                </h1>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p
                    style={{
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    If you use or provide services on the Site for which we implement a billing
                    system for you, we will collect additional information from you so that we
                    can process and collect billing information. For example, we may collect
                    your mailing address to remit payments. We use third party for the payment
                    gateway option in our website. The information shared by you during the
                    payment through our payment gateway will be stored and accessed by the third
                    party. We are neither way responsible for the details you share with them,
                    however as per terms and conditions agreed by both us and payment gate way
                    service provider are against mishandling of user data.
                </p>
                <h1
                    style={{
                        paddingTop: "10pt",
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    Cookies:
                </h1>
                <p style={{ paddingTop: "1pt", textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p
                    style={{
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    The Site uses software tags called “Cookies” to identify customers when they
                    visit our Site. Cookies are used to remember user preferences and maximize
                    performance of our services. The information we collect with cookies is not
                    sold, rented, or shared with any outside parties. Users who disable their
                    Web browser’s ability to accept cookies will be able to browse our Site but
                    may not be able to successfully use our Service.
                </p>
                <p
                    style={{
                        paddingTop: "12pt",
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    This privacy policy covers the use of cookies by our Site only and does not
                    cover the use of cookies by any advertisers.
                </p>
                <h1
                    style={{
                        paddingTop: "10pt",
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    Third Party Cookies:
                </h1>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p
                    style={{
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    We may from time to time engage third parties to track and analyze non-personally identifiable usage and volume statistical information from visitors to our Site to help us administer our Site and improve its quality. Such third parties may use cookies to help track visitor behaviour. Such cookies will not be used to associate individual Site visitors to any Personal Information. All data collected by such third parties on our behalf is used only to provide us with information on Site usage and is not shared with any other third parties.
                </p>
                <h1
                    style={{
                        paddingTop: "10pt",
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    Data Sharing
                </h1>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p
                    style={{
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    Access to your name, email address and private details is restricted to our employees who need to know such information in connection with our services and are bound by confidentiality obligations. All the data are collected and stored to improve the quality of service we provide time to time by observing market and student requirement.
                </p>
                <h1
                    style={{
                        paddingTop: "10pt",
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    What We Use Your Data For

                </h1>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p
                    style={{
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    We use your data to do things like provide our Services, communicate with you, troubleshoot issues, secure against fraud and abuse, improve and update our Services, analyze how people use our Services, serve personalized advertising, and as required by law or necessary for safety and integrity.
                </p>
                <h1
                    style={{
                        paddingTop: "10pt",
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    Who We Share Your Data With
                </h1>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p
                    style={{
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    We share certain data about you with instructors, other students, companies performing services for us, our business partners, analytics and data enrichment providers, your social media providers, companies helping us run promotions and surveys, and advertising companies who help us promote our Services. We may also share your data as needed for security, legal compliance, or as part of a corporate restructuring. Lastly, we can share data in other ways if it is aggregated or de-identified or if we get your consent.
                </p>
                <h1
                    style={{
                        paddingTop: "10pt",
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    Sharing Your Personal Information
                </h1>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p
                    style={{
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    If you are a student of College planet, we may ask you for testimonial, we will display your testimonial on our website, photos and videos or in our social media channels such as Facebook, YouTube or Linkedin. You should be aware that your publicly identifiable information could be used to send you promotional, unsolicited messages. We are not responsible for your personal information which you have chosen to display.
                </p>
                <p
                    style={{
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    If you don’t want us to feature your pictures/testimonials on our website or on our social media channels, you can write a mail to trainings@gdcs.in
                </p>
                <h1
                    style={{
                        paddingTop: "10pt",
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    Security
                </h1>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p
                    style={{
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    We use appropriate security based on the type and sensitivity of data being stored. As with any internet-enabled system, there is always a risk of unauthorized access, so it’s important to protect your password and to contact us if you suspect any unauthorized access to your account.
                </p>
                <h1
                    style={{
                        paddingTop: "10pt",
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        textAlign: "left"
                    }}
                >
                    Change In This Privacy Policy
                </h1>
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                    <br />
                </p>
                <p
                    style={{
                        paddingLeft: "32pt",
                        textIndent: "0pt",
                        lineHeight: "130%",
                        textAlign: "left"
                    }}
                >
                    We may occasionally update this Privacy Policy. When we do, we will also revise the “last updated” date on the Privacy Policy. For changes to this Privacy Policy that may be materially less restrictive on our use or disclosure of personal information you have provided to us, we will attempt to obtain your consent before implementing the change. We encourage you to periodically review this Privacy Policy to stay informed about how we are protecting the personal information we collect. Your continued use of this Site constitutes your agreement to this Privacy Policy and any updates.
                </p>
            </body>

        </>
    );
}

export default Privacy;