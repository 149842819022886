// src/components/CategoryCoursesCount.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../firebaseconfig';

const CategoryCoursesCount = ({ categoryId }) => {
  const [courseCount, setCourseCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourseCount = async () => {
      try {
        const q = query(collection(db, `categories/${categoryId}/courses`));
        const querySnapshot = await getDocs(q);
        setCourseCount(querySnapshot.size);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseCount();
  }, [categoryId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div style={{fontFamily:'"DM Sans", sans-serif'}}>{courseCount} Courses</div>;
};

export default CategoryCoursesCount;
