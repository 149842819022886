import { useEffect } from 'react';

const TawkToWidget = () => {
    useEffect(() => {
        // Append the Tawk.to script to the document
        // var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        (function () {
            var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
            (function () {
                var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
                s1.async = true;
                s1.src = 'https://embed.tawk.to/66b1d42a32dca6db2cba7519/1i4ja9hvl';
                s1.charset = 'UTF-8';
                s1.setAttribute('crossorigin', '*');
                s0.parentNode.insertBefore(s1, s0);
            })();

        })();
    }, []);

    return null; // This component does not render anything
};

export default TawkToWidget;
