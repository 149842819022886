import firebase from 'firebase/compat/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyBpvbbpWRiwk4Q0sRoahuZEjvGi94hyTYg",
    authDomain: "college-planet.firebaseapp.com",
    projectId: "college-planet",
    storageBucket: "college-planet.appspot.com",
    messagingSenderId: "789166666704",
    appId: "1:789166666704:web:2292bc5d5d631b2527ffd5",
    measurementId: "G-THXLMP8XN8"
  };
  
  const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
  export const db = getFirestore(app);

  export default firebase;