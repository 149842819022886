import React from "react";
import './Email.css';

const Email = () => {
    return (
        <section
            className="email_section email_newsletter"
            aria-label="newsletter"
        >
            <div className="email_container">
                <p className="email_section-subtitle">Subscribe Newsletter</p>
                <h2 className="email_h2 email_section-title">Get Every Latest News</h2>
                <form action="" className="email_newsletter-form">
                    <div className="email_input-wrapper">
                        <input
                            type="email"
                            name="email_address"
                            aria-label="email"
                            placeholder="Enter your mail address"
                            required="Email is reqiuired"
                            className="email-field"
                        />
                        <ion-icon name="mail-open-outline" aria-hidden="true" />
                    </div>
                    <button type="submit" className="email_btn email_btn-primary">
                        <span className="email_span">Subscribe</span>
                        <ion-icon name="arrow-forward-outline" aria-hidden="true" />
                    </button>
                </form>
            </div>
        </section>

    );
}

export default Email;