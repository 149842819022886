import React, { useEffect, useState } from "react";
import "./CourseDetails.css";
import certificate1 from "../../assets/images/sempel.jpeg";
import certificate2 from "../../assets/images/sempel-2.jpeg";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseconfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../utils/fontAwesomeConfig";
import { Rating } from "@mui/material";
import ModalForm from "../Enroll/EnrollNow";
// import { useSelector, useDispatch } from 'react-redux'
import discountimage from "../../assets/images/Collab.gif";
import whocanenrollimg from "../../assets/images/Student stress.gif";
import whoadmissioncanenrollimg from "../../assets/images/college admission.gif";
import whyLearnPic from "../../assets/images/Dictionary.gif";
import certificateImg from "../../assets/images/_Certificate 1.png";
import certificateImg2 from "../../assets/images/_Certificate 2.png";


const Accordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(0);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <div className="accordion-header" onClick={() => handleClick(index)}>
            <h2>
              {item.title} <span>{index === openIndex ? "︿" : "﹀"}</span>
            </h2>
          </div>
          {index === openIndex && (
            <div className="accordion-body">
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const HighLight = ({ title, subtitle, icon }) => {
  return (
    <div class="highlight-card">
      <div class="highlight-img">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div class="textBox">
        <div class="textContent">
          <p class="h1">{title}</p>
        </div>
        <p class="p">{subtitle}</p>
      </div>
    </div>
  );
};

export default function CourseDetails() {
  const { cat, courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const snapshot = await getDoc(
          doc(db, `categories/${cat}/courses/${courseId}`)
        );
        setCourse(snapshot.data());
      } catch (e) {
        console.error("Error to fetch Data.." + e);
      }
    };
    fetchCourse();
    // console.log(course);
  }, [cat, courseId, course]);

  function formatDate(dateStr) {
    // Parse the date string (assumes DD/MM/YYYY format)
    const [year, month, day] = dateStr.split("-").map(Number);
    const date = new Date(year, month - 1, day);

    // Get day with suffix
    const dayWithSuffix = (d) => {
      const suffix = ["th", "st", "nd", "rd"];
      const remainder = d % 10;
      if (d > 3 && d < 21) return `${d}th`;
      return `${d}${suffix[remainder] || "th"}`;
    };

    // Get month name
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNames[month - 1];

    // Format the date string
    return `${dayWithSuffix(day)} ${monthName} ${year}`;
  }

  function whyLearn(why) {
    return why.split("\n").filter((item) => item.trim());
  }

  const accordionItems = [
    { title: "Accordion Item 1", content: "Content for item 1 " },
    { title: "Accordion Item 2", content: "Content for item 2" },
    { title: "Accordion Item 3", content: "Content for item 3" },
  ];

  return (
    <section className="Course_Details_container">
      {course && (
        <div>
          <ModalForm
            isOpen={open}
            category={course.category}
            course={courseId}
            price={course.discountPrice}
            onClose={() => setOpen(false)}
            name={course.title}
          />

          <div className="Course_Details_header">
            <div className="Course_Details_header_Content">
              <div className="header_Content_rating">
                <Rating
                  name="half-rating-read"
                  defaultValue={course.ratings}
                  precision={0.5}
                  readOnly
                />
              </div>
              <div className="header_Content_title">
                <h3>{course.title}</h3>
              </div>
              <div className="header_Content_subtitle">
                <p>{course.subtitle}</p>
              </div>
              <a href="#fees">
                <button className="enroll_now_button">Enroll Now</button>
              </a>
            </div>
            <div className="Course_Details_header_Image">
              <img src={course.imageUrl} />
            </div>
          </div>

          <div className="course-information">
            <div className="course-information-row">
              <div className="course-information-row-content-batch">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="currentColor"
                    d="M208 34h-26V24a6 6 0 0 0-12 0v10H86V24a6 6 0 0 0-12 0v10H48a14 14 0 0 0-14 14v160a14 14 0 0 0 14 14h160a14 14 0 0 0 14-14V48a14 14 0 0 0-14-14M48 46h26v10a6 6 0 0 0 12 0V46h84v10a6 6 0 0 0 12 0V46h26a2 2 0 0 1 2 2v34H46V48a2 2 0 0 1 2-2m160 164H48a2 2 0 0 1-2-2V94h164v114a2 2 0 0 1-2 2m-70-78a10 10 0 1 1-10-10a10 10 0 0 1 10 10m44 0a10 10 0 1 1-10-10a10 10 0 0 1 10 10m-88 40a10 10 0 1 1-10-10a10 10 0 0 1 10 10m44 0a10 10 0 1 1-10-10a10 10 0 0 1 10 10m44 0a10 10 0 1 1-10-10a10 10 0 0 1 10 10"
                  />
                </svg>
                <p>Course starts </p>
                <h4>{formatDate(course.startFrom)}</h4>
              </div>
              <div className="course-program-fees-divider" />
              <div className="course-information-row-content-duration">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 432 432"
                >
                  <path
                    fill="currentColor"
                    d="M213.5 3q88.5 0 151 62.5T427 216t-62.5 150.5t-151 62.5t-151-62.5T0 216T62.5 65.5T213.5 3zm0 384q70.5 0 120.5-50t50-121t-50-121t-120.5-50T93 95T43 216t50 121t120.5 50zM224 109v112l96 57l-16 27l-112-68V109h32z"
                  />
                </svg>
                <p>Duration</p>
                <h4>
                  {course.duration} {course.durationCat}s
                </h4>
              </div>
              <div className="course-program-fees-divider" />
              <div className="course-information-row-content-mode">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="currentColor"
                    d="M208 40H48a24 24 0 0 0-24 24v112a24 24 0 0 0 24 24h72v16H96a8 8 0 0 0 0 16h64a8 8 0 0 0 0-16h-24v-16h72a24 24 0 0 0 24-24V64a24 24 0 0 0-24-24ZM48 56h160a8 8 0 0 1 8 8v80H40V64a8 8 0 0 1 8-8Zm160 128H48a8 8 0 0 1-8-8v-16h176v16a8 8 0 0 1-8 8Z"
                  />
                </svg>
                <p>Course mode</p>
                <h4>{course.mode}</h4>
              </div>
              <div className="course-program-fees-divider" />
              <div className="course-information-row-content-letter">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="currentColor"
                    d="M248 128a56 56 0 1 0-96 39.14V224a8 8 0 0 0 11.58 7.16L192 216.94l28.42 14.22A8 8 0 0 0 232 224v-56.86A55.81 55.81 0 0 0 248 128Zm-56-40a40 40 0 1 1-40 40a40 40 0 0 1 40-40Zm3.58 112.84a8 8 0 0 0-7.16 0L168 211.06v-32.47a55.94 55.94 0 0 0 48 0v32.47ZM136 192a8 8 0 0 1-8 8H40a16 16 0 0 1-16-16V56a16 16 0 0 1 16-16h176a16 16 0 0 1 16 16a8 8 0 0 1-16 0H40v128h88a8 8 0 0 1 8 8Zm-16-56a8 8 0 0 1-8 8H72a8 8 0 0 1 0-16h40a8 8 0 0 1 8 8Zm0-32a8 8 0 0 1-8 8H72a8 8 0 0 1 0-16h40a8 8 0 0 1 8 8Z"
                  />
                </svg>
                <p>Course certification</p>
                <h4>LOR & Internship Letter</h4>
              </div>
            </div>
          </div>

          {/* Course Highlights */}

          {/* Check/ highlights */}
          <div
            style={{
              width: "100%",
              justifyContent: "space-around",
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              marginTop: "3%",
              marginBottom: "5%",
              flexDirection: "column",
            }}
          >
            <h2 className="highlights-text">Course HighLights</h2>
            <div
              style={{
                width: "90%",
                justifyContent: "space-around",
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                marginTop: "1%",
              }}
            >
              <HighLight
                title={"Learn from"}
                subtitle={"Industry Export"}
                icon={"fa-solid fa-book-open-reader"}
              />
              <HighLight
                title={"Participants may apply"}
                subtitle={"For LOR after the program."}
                icon={"fa-solid fa-envelope-open-text"}
              />
              <HighLight
                title={"100% live sessions"}
                subtitle={"With 1:1 mentorship"}
                icon={"fa-solid fa-chalkboard-user"}
              />
              <HighLight
                title={"Students will get the"}
                subtitle={"Industrialt-certified online training"}
                icon={"fa-solid fa-industry"}
              />
              <HighLight
                title={"Students will get direct"}
                subtitle={"counseling from the course expert."}
                icon={"fa-solid fa-phone-volume"}
              />
              <HighLight
                title={"Best programs to"}
                subtitle={"building for Higher study or MNC jobs."}
                icon={"fa-solid fa-graduation-cap"}
              />
              <HighLight
                title={"Globally Accepted Certificate"}
                subtitle={"For a global reach"}
                icon={"fa-solid fa-globe"}
              />
              <HighLight
                title={"Best in the market"}
                subtitle={"But Most affordable in the market"}
                icon={"fa-solid fa-indian-rupee-sign"}
              />
              <HighLight
                title={"Best in class delivery"}
                subtitle={"Experience Online mode by experts from Industry"}
                icon={"fa-solid fa-house-laptop"}
              />
              <HighLight
                title={"Give Yourself Chance"}
                subtitle={"To Upgrade Your Skill with this Course"}
                icon={"fa-solid fa-arrow-up-right-dots"}
              />
              {/* <HighLight title={""} subtitle={""} icon={""}/> */}
              {/* <HighLight title={""} subtitle={""} icon={""}/> */}
            </div>
          </div>

          {/* Industry certification */}
          <div className=" industry ">
            <center>
              <h2 className="Industry-recognized-certification">Industry recognized certification</h2>
            </center>
            <div className="certificate">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1%",
                }}
              >
                <p>Course Completion Certificate</p>
                <img src={certificateImg} alt="certificate1" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1%",
                }}
              >
                <p>Course Participation Certificate</p>
                <img src={certificateImg2} alt="certificate2" />
              </div>
            </div>
          </div>

          {/* Who can Enroll and Admission */}
          <div className="whocan-admission">
            <div className="whocan-enroll">
              <div style={{ paddingRight: "10px" }}>
                <h2>Who Can Enroll ?</h2>
                <div dangerouslySetInnerHTML={{ __html: course.enroll }} className="whocan-enroll-inner"/>
              </div>
            </div>
            <div className="why-enroll">
              <div style={{ paddingRight: "10px" }} className="top-area">
                <h2>Why Learn {course.title} ?</h2>
                <div>
                  <p style={{ textAlign: "justify" }}>
                    {whyLearn(course.whyLearn)}
                  </p>
                </div>
              </div>
              <div>
                <img
                  src={whyLearnPic}
                  alt="whyLearn"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="admission-details">
              <div style={{ paddingLeft: "10px" }}>
                <h2>Admission Details</h2>
                <div dangerouslySetInnerHTML={{ __html: course.admission }} />
              </div>
              <div>
                <img src={whoadmissioncanenrollimg} style={{ width: "100%" }} />
              </div>
            </div>
          </div>

          {/* How we work for you */}
          <div className="how-work">
            <center>
              <h2>How we work for you?</h2>
            </center>
            <div className="how-work-container">
              <div className="how-work-cards ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" stroke="currentColor" stroke-width="2">
                    <path d="M4 7c0-1.886 0-2.828.586-3.414C5.172 3 6.114 3 8 3h8c1.886 0 2.828 0 3.414.586C20 4.172 20 5.114 20 7v8c0 2.828 0 4.243-.879 5.121C18.243 21 16.828 21 14 21h-4c-2.828 0-4.243 0-5.121-.879C4 19.243 4 17.828 4 15z" />
                    <path
                      stroke-linecap="round"
                      d="M15 18v3m-6-3v3M9 8h6m-6 4h6"
                    />
                  </g>
                </svg>
                <p>Fill up the form</p>
                <h2>
                  The journey starts with filling the form on the Courses page.
                </h2>
              </div>
              <div className="how-work-cards ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 12.5a3.5 3.5 0 1 0 0 7a3.5 3.5 0 0 0 0-7ZM10.5 16a1.5 1.5 0 1 1 3 0a1.5 1.5 0 0 1-3 0Z"
                  />
                  <path
                    fill="currentColor"
                    d="M17.526 5.116L14.347.659L2.658 9.997L2.01 9.99V10H1.5v12h21V10h-.962l-1.914-5.599l-2.098.715ZM19.425 10H9.397l7.469-2.546l1.522-.487L19.425 10ZM15.55 5.79L7.84 8.418l6.106-4.878l1.604 2.25ZM3.5 18.169v-4.34A3.008 3.008 0 0 0 5.33 12h13.34a3.009 3.009 0 0 0 1.83 1.83v4.34A3.009 3.009 0 0 0 18.67 20H5.332A3.01 3.01 0 0 0 3.5 18.169Z"
                  />
                </svg>
                <p>Pay the courses fees</p>
                <h2>Pay the Registration fees and you are enrolled</h2>
              </div>
              <div className="how-work-cards ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 32 32"
                >
                  <g fill="none">
                    <g
                      fill="currentColor"
                      clip-path="url(#fluentEmojiHighContrastSeat0)"
                    >
                      <path d="M13.178 27H25v1.78c0 .13-.105.22-.206.22H13.898a.265.265 0 0 1-.249-.204L13.178 27Z" />
                      <path d="m16.264 16l.271 1h8.253C26.587 17 28 18.469 28 20.228v3.544c0 .433-.086.848-.241 1.229c.155.381.241.797.241 1.229v2.55c0 1.748-1.409 3.22-3.206 3.22H13.898a3.264 3.264 0 0 1-3.15-2.442l-.638-2.43a3.303 3.303 0 0 1-.08-1.285a3.308 3.308 0 0 1-.658-1.247l-1.435-4.687a2.251 2.251 0 0 1-.089-.444L4.116 5.718c-.484-1.743.58-3.51 2.3-3.955l2.464-.651a3.243 3.243 0 0 1 3.188.894c1.348-.124 2.494.833 2.788 2.041l1.066 4.282l.003.01c.21.888-.011 1.763-.59 2.415a2.82 2.82 0 0 1-.396.367l.51 1.879H21.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5.236Zm-5.289 7h.008l.31 1.039l.001.003a1.287 1.287 0 0 0 .308.56c.233.249.56.398.915.398h12.27A1.22 1.22 0 0 0 26 23.772v-3.544A1.22 1.22 0 0 0 24.788 19h-9.796a1.18 1.18 0 0 0-.036-.184L14.19 16H11.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h1.877l-.827-3.043h-.003L11.028 4.34l-.002.001l-.118-.434a1.231 1.231 0 0 0-1.51-.864L6.92 3.7a1.207 1.207 0 0 0-.876 1.489l3.802 14a.248.248 0 0 0 .003.135L10.975 23Zm1.068 2.95a1.3 1.3 0 0 0 .001.67l.638 2.431c.148.56.647.949 1.216.949h10.896C25.46 30 26 29.45 26 28.78v-2.55c0-.187-.04-.364-.114-.523a2.189 2.189 0 0 1-1.098.293H12.517a2.25 2.25 0 0 1-.474-.05Z" />
                    </g>
                    <defs>
                      <clipPath id="fluentEmojiHighContrastSeat0">
                        <path fill="#fff" d="M0 0h32v32H0z" />
                      </clipPath>
                    </defs>
                  </g>
                </svg>
                <p>Seat confirmation</p>
                <h2>It takes 12 hours for seat confirmation</h2>
              </div>
            </div>
          </div>

          {/* Program fees  */}
          <div id="fees" className="course-program-fees">
            <div className="course-program-fees-first-row">
              <div
                style={{
                  width: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                  textAlign: "justify",
                  marginBottom: "3%",
                  marginTop: "0%",
                }}
              >
                <p>
                  100% Live classes by Experts & After live classes recordings
                  will be shared
                </p>
              </div>
              <div className="course-program-fees-divider" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                  textAlign: "justify",
                  marginBottom: "3%",
                  marginTop: "3%",
                }}
                className="course-price-area"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "3%",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M6.5 3.5h11m-11 4.722h11M14.292 20.5L6.5 12.944h2.75c6.111 0 6.111-9.444 0-9.444"
                    />
                  </svg>
                  <h2>{course.discountPrice}/-</h2>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M6.5 3.5h11m-11 4.722h11M14.292 20.5L6.5 12.944h2.75c6.111 0 6.111-9.444 0-9.444"
                    />
                  </svg>
                  <h4
                    style={{ textDecorationLine: "line-through" }}
                    className="course-base-price"
                  >
                    {course.basePrice}/-
                  </h4>
                </div>
                <div style={{ width: "100%" }}>
                  <button
                    onClick={() => setOpen(true)}
                    style={{
                      width: "100%",
                      backgroundColor: "#FFE21F",
                      padding: "10px",
                      borderRadius: "10px",
                      color: "black",
                      border: "0.5px solid black",
                      display:'flex',
                      justifyContent:'center',
                      alignItems: 'center',
                      gap:'10px'
                    }}
                  >
                   
                    Enroll Now
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 36 36"
                    >
                      <path
                        fill="black"
                        d="M28.69 14.33v4.83l-2-.43v-5.49a16.19 16.19 0 0 0-2.33-.84v5.82l-2-.43V12c-1.1-.18-2.18-.3-3.08-.36v5.51l-2-.43V4.34a2.53 2.53 0 0 0-2.6-2.43a2.53 2.53 0 0 0-2.6 2.43v15.52l-2 1V15.6l-2.33-2.39a2.83 2.83 0 0 0-4 0a2.93 2.93 0 0 0 0 4.09l6 7.1a10.82 10.82 0 0 0 1.39 4.22a8.42 8.42 0 0 0 2.21 2.73v2.56h14.44v-3.29a12.54 12.54 0 0 0 3-8.5v-6a10 10 0 0 0-2.1-1.79Z"
                        class="clr-i-solid clr-i-solid-path-1"
                      />
                      <path fill="none" d="M0 0h36v36H0z" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="course-program-fees-divider" />

              <div
                style={{
                  width: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  //   alignItems: "center",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                  textAlign: "justify",
                  gap: "8px",
                  marginTop: "0%",
                }}
              >
                <p>1.Live Practical, Doubt clearing, Mentorship Sessions</p>
                <p>2.Join in a cohort and network with fellow classmates</p>
                <p>
                  3.Certificate of participation, Internship letter, and LOR
                  will be given after completion of the course.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
          </div>

          {/* Course Content using accordion */}
          <div className="course-accordion">
            <div className="course-accordion-first-row">
              <h1>
                Give Yourself Chance To Upgrade Your Skill with Gdcs Trainings
              </h1>
              <Accordion items={course.content} />
            </div>
            <div className="discount-area">
              <img src={discountimage} />
              <div>
                <h2>Group discount</h2>
                <h3>5% further discount for group registration available.</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
