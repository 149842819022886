import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Modal,
} from "@mui/material";
import "./EnrollNow.css";
import useRazorpay from "react-razorpay";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebaseconfig";
import CloseIcon from "@mui/icons-material/Close";
import logo from '../../assets/images/lightLogo.png'

const ModalForm = ({ isOpen, onClose, course, category, price, name }) => {
  const registrationNoGenerator = () => {
    const year = new Date().getFullYear().toString().slice(2);
    const mth = (new Date().getMonth() + 1).toLocaleString().padStart(2, "0");
    return year + mth;
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    contactNumber: "",
    email: "",
    category: category,
    course: course,
    qualification: "",
    semestar: "",
    branch: "",
    college: "",
    address: "",
    landmark: "",
    city: "",
    district: "",
    state: "",
    pincode: "",
    price: price,
    program: "",
  });

  const [errors, setErrors] = useState({});
  const [Razorpay] = useRazorpay();

  useEffect(() => {
    console.log(formData.category + " " + formData.course);
  }, [formData.category, formData.course]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.contactNumber) {
      newErrors.contactNumber = "Contact Number is required";
    } else if (!/^\d{10}$/.test(formData.contactNumber)) {
      newErrors.contactNumber = "Contact Number must be a 10-digit number";
    }
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.category) newErrors.category = "Category is required";
    if (!formData.course) newErrors.course = "Course is required";
    if (!formData.qualification)
      newErrors.qualification = "Qualification is required";
    if (!formData.semestar) newErrors.semestar = "Semestar is required";
    if (!formData.branch) newErrors.branch = "Branch is required";
    if (!formData.college) newErrors.college = "College Name is required";
    if (!formData.address) newErrors.address = "Address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.district) newErrors.district = "District is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.pincode) newErrors.pincode = "Pincode is required";
    if (!formData.program) newErrors.program = "Program is required";

    setErrors(newErrors);
    console.log(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = () => {
    if (validateForm()) {
      try {
        setDoc(doc(db, "Students", "student1"), formData);
        console.log("Form data submitted:", formData);
        alert("Success");
      } catch (e) {
        console.error("Error: " + e);
      }
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="enquiry-form-modal"
      aria-describedby="enquiry-form-modal-description"
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
          margin: "0 auto",
          padding: { xs: "10px", sm: "20px" },
          boxShadow: 2,
          bgcolor: "background.paper",
          mt: { xs: "5%", md: "1%" },
          maxHeight: "95vh",
          overflowY: "auto",
          borderRadius: "16px",
          // Hide scrollbar
          scrollbarWidth: "none" /* For Firefox */,
          "-ms-overflow-style": "none" /* For Internet Explorer and Edge */,
          "&::-webkit-scrollbar": {
            display: "none" /* For Chrome, Safari, and Opera */,
          },
        }}
      >
        <Typography variant="h6" component="h5" gutterBottom>
          <div style={{width:'100%',display:'flex',justifyContent:'space-between',alignItems:'center'}}>

          <img src={logo} style={{height:'100px',width:'100px'}}/>
          <CloseIcon
            onClick={onClose}
            style={{ cursor: "pointer", float: "right" }}
          />
          </div>
        </Typography>
        {/* <Typography variant="h6" component="h5" gutterBottom>
          "Course Category Name"
        </Typography> */}

        <Typography variant="h6" component="h5" gutterBottom>
        "{name}"
        </Typography>

        <Grid container spacing={2}>
          {/* Name */}
          <Grid item xs={12}>
            <Stack spacing={1} direction={{ xs: "column", sm: "row" }} gap={2}>
              <TextField
                fullWidth
                name="firstName"
                label="First Name"
                value={formData.firstName}
                onChange={handleChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
                size="small"
              />
              <TextField
                fullWidth
                name="lastName"
                label="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                error={!!errors.lastName}
                helperText={errors.lastName}
                size="small"
              />
            </Stack>
          </Grid>

          {/* Gender */}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              sx={{ marginBottom: { xs: "8px", sm: "8px", md: "8px" } }}
            >
              Gender*
            </Typography>
            <RadioGroup
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </Grid>

          {/* Rest of the Form Fields */}
          <Grid item xs={12}>
            <Typography
              variant="h9"
              sx={{ marginBottom: { xs: "8px", sm: "8px", md: "8px" } }}
            >
              Whatsapp Nummber*
            </Typography>
            <TextField
              fullWidth
              label="Whatsapp Number"
              name="contactNumber"
              type="tel"
              value={formData.contactNumber}
              onChange={handleChange}
              error={!!errors.contactNumber}
              helperText={errors.contactNumber}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h9"
              sx={{ marginBottom: { xs: "8px", sm: "8px", md: "8px" } }}
            >
              Email*
            </Typography>
            <TextField
              fullWidth
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              // required
              size="small"
              placeholder="Email"
            />
          </Grid>
          {/* Qualification */}
          <Grid item xs={12}>
            <Typography
              variant="h9"
              sx={{ marginBottom: { xs: "8px", sm: "8px", md: "8px" } }}
            >
              Qualification*
            </Typography>
            <TextField
              fullWidth
              select
              name="qualification"
              value={formData.qualification}
              onChange={handleChange}
              error={!!errors.qualification}
              helperText={errors.qualification}
              // required
              size="md"
              label="Select Qualification"
            >
              <MenuItem value="diploma">Diploma</MenuItem>
              <MenuItem value="btech/be">BTech / BE</MenuItem>
              <MenuItem value="mtech">MTech</MenuItem>
              <MenuItem value="iti">ITI</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
          {/* Semester */}
          <Grid item xs={12}>
            <Typography
              variant="h9"
              sx={{ marginBottom: { xs: "8px", sm: "8px", md: "8px" } }}
            >
              Semester*
            </Typography>
            <TextField
              fullWidth
              select
              name="semestar"
              value={formData.semestar}
              onChange={handleChange}
              error={!!errors.semestar}
              helperText={errors.semestar}
              // required
              size="small"
              label="Select Semester"
            >
              <MenuItem value="1st">1st</MenuItem>
              <MenuItem value="2nd">2nd</MenuItem>
              <MenuItem value="3rd">3rd</MenuItem>
              <MenuItem value="4th">4th</MenuItem>
              <MenuItem value="5th">5th</MenuItem>
              <MenuItem value="6th">6th</MenuItem>
              <MenuItem value="passout">Passout</MenuItem>
            </TextField>
          </Grid>
          {/* Branch */}
          <Grid item xs={12}>
            <Typography
              variant="h9"
              sx={{ marginBottom: { xs: "8px", sm: "8px", md: "8px" } }}
            >
              Branch of Study*
            </Typography>
            <TextField
              fullWidth
              select
              name="branch"
              value={formData.branch}
              onChange={handleChange}
              error={!!errors.branch}
              helperText={errors.branch}
              size="small"
              placeholder="Select Branch of Study"
              label="Select Branch of Study"
            >
              <MenuItem value="mechanical">Mechanical</MenuItem>
              <MenuItem value="automobile">Automobile</MenuItem>
              <MenuItem value="ee/eee">EE/EEE</MenuItem>
              <MenuItem value="civel">Civil</MenuItem>
              <MenuItem value="mechatronics">Mechatronics</MenuItem>
              <MenuItem value="aero">Aero</MenuItem>
              <MenuItem value="it/cs">IT / CS</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
          {/* College */}
          <Grid item xs={12}>
            <Typography
              variant="h9"
              sx={{ marginBottom: { xs: "8px", sm: "8px", md: "8px" } }}
            >
              College / University Name*
            </Typography>
            <TextField
              fullWidth
              type="text"
              name="college"
              value={formData.college}
              onChange={handleChange}
              error={!!errors.contactNumber}
              helperText={errors.contactNumber}
              // required
              size="small"
              placeholder="College / University Name"
            />
          </Grid>
          {/* Address */}
          <Grid item xs={12}>
            <Typography
              variant="h9"
              sx={{ marginBottom: { xs: "8px", sm: "8px", md: "8px" } }}
            >
              City*
            </Typography>
            <TextField
              fullWidth
              name="city"
              value={formData.city}
              onChange={handleChange}
              error={!!errors.city}
              helperText={errors.city}
              // required
              size="small"
              placeholder="City"
            />
            <div className="address-line-3">
              <div>
                <Typography
                  variant="h9"
                  sx={{ marginBottom: { xs: "8px", sm: "8px", md: "8px" } }}
                >
                  State*
                </Typography>
                <TextField
                  fullWidth
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  error={!!errors.state}
                  helperText={errors.state}
                  // required
                  size="small"
                  placeholder="State"
                />
              </div>
              <div>
                <Typography
                  variant="h9"
                  sx={{ marginBottom: { xs: "8px", sm: "8px", md: "8px" } }}
                >
                  Pincode*
                </Typography>
                <TextField
                  fullWidth
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  error={!!errors.pincode}
                  helperText={errors.pincode}
                  // required
                  size="small"
                  placeholder="Pincode"
                />
              </div>
            </div>
          </Grid>
          {/* Program */}
          <Grid item xs={12}>
            <Typography
              variant="h9"
              sx={{ marginBottom: { xs: "8px", sm: "8px", md: "8px" } }}
            >
              How did you know about the program?
            </Typography>
            <RadioGroup
              name="program"
              value={formData.program}
              onChange={handleChange}
              error={!!errors.program}
              helperText={errors.program}
              // required
              size="small"
            >
              <FormControlLabel
                value="Social Media"
                control={<Radio />}
                label="Social Media"
              />
              <FormControlLabel
                value="Whatsapp Group"
                control={<Radio />}
                label="Whatsapp Group"
              />
              <FormControlLabel
                value="From College / Faculty"
                control={<Radio />}
                label="From College / Faculty"
              />
              <FormControlLabel
                value="E-mail/Call or Message from CP"
                control={<Radio />}
                label="E-mail/Call or Message from CP"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              // required
              control={<Checkbox />}
              label="I have read and agree to the Terms and Conditions and Privacy Policy"
            />
          </Grid>
          {/* Similarly adjust other fields like Email, Address, etc. */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#FFE21F",
                color: "black",
                border: "1px solid black",
                borderRadius: "20px",
              }}
              onClick={handleSubmit}
            >
              Pay 5999/-
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalForm;
