import React from "react";
import './StudentEnrolled.css';

const StudentEnroll = () => {
    return (
        <section className="section_Enr stats_Enr" aria-label="stats">
            <div className="container_Enr">
                <ul className="grid-list_Enr">
                    <li>
                        <div className="stats-card_Enr" style={{backgroundColor: "#E8F7F5",}}>
                            <h3 className="card-title_Enr">29.3k</h3>
                            <p className="card-text_Enr">Student Enrolled</p>
                        </div>
                    </li>
                    <li>
                        <div className="stats-card_Enr" style={{backgroundColor: "#FDECEF",}}>
                            <h3 className="card-title_Enr">32.4K</h3>
                            <p className="card-text_Enr">Class Completed</p>
                        </div>
                    </li>
                    <li>
                        <div className="stats-card_Enr" style={{backgroundColor: "#F3EEFF",}}>
                            <h3 className="card-title_Enr">100%</h3>
                            <p className="card-text_Enr">Satisfaction Rate</p>
                        </div>
                    </li>
                    <li>
                        <div className="stats-card_Enr" style={{backgroundColor: "#FEF7E8",}}>
                            <h3 className="card-title_Enr">354+</h3>
                            <p className="card-text_Enr">Top Instructors</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>

    );
}

export default StudentEnroll;