import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    darkmode: localStorage.getItem("darkMode")  
    ? JSON.parse(localStorage.getItem("darkMode")) : false,
    // darkmode: true,
}

export const darkSlice = createSlice({
    name: 'darkmode',
    initialState,
    reducers: {
        setDarkmode: (state, action) => {
            state.darkmode = action.payload;
            localStorage.setItem('darkMode', JSON.stringify(state.darkmode)); 
        }
    }
});

export const { setDarkmode } = darkSlice.actions;
export default darkSlice.reducer;
