import React from "react";
import "./About.css";

const About = ({ id }) => {
  return (
    <>
      <section className="section_Abt about_Abt" id={id} aria-label="about">
        <div className="container_Abt">
          <figure className="about-banner_Abt">
            <img
              src="https://github.com/codewithsadee/eduhome/blob/master/assets/images/about-banner.jpg?raw=true"
              width={450}
              height={590}
              loading="lazy"
              alt="about banner"
              className="w-100_Abt about-img_Abt"
            />
          </figure>
          <div className="about-content_Abt">
            <p className="section-subtitle_Abt">Who We Are</p>
            <h2 className="h2_Abt section-title_Abt">
              We Offer The Best Carrier
            </h2>
            <ul className="about-list_Abt">
              <li className="about-item_Abt">
                <div className="item-icon_Abt item-icon_Abt-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 36 36"
                  >
                    <path
                      fill="#CCD6DC"
                      d="M30 26a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h22a4 4 0 0 1 4 4v22z"
                    />
                    <path
                      fill="#F5F8FA"
                      d="M28 26a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h22a2 2 0 0 1 2 2v22z"
                    />
                    <path
                      fill="#50A5E6"
                      d="M6 26a1 1 0 0 1-1-1V13a1 1 0 1 1 2 0v12a1 1 0 0 1-1 1z"
                    />
                    <path
                      fill="#77B255"
                      d="M10 26a1 1 0 0 1-1-1V8a1 1 0 1 1 2 0v17a1 1 0 0 1-1 1z"
                    />
                    <path
                      fill="#DD2F45"
                      d="M14 26a1 1 0 0 1-1-1v-7a1 1 0 1 1 2 0v7a1 1 0 0 1-1 1z"
                    />
                    <path
                      fill="#226798"
                      d="M36 36v-2c0-3.314-2.685-6-6-6H14a6 6 0 0 0-6 6v2h28z"
                    />
                    <path
                      fill="#3A87C2"
                      d="M16.667 36H20.2L17 28h-2l-1 6l3 1zm10.666 0H23.8l3.2-8h2l1 6l-3 1z"
                    />
                    <path
                      fill="#D4AB88"
                      d="M17.64 28.101c1.744 1.268 2.857 2.032 4.37 2.032c1.512 0 2.606-.766 4.35-2.032V24.29h-8.72v3.811z"
                    />
                    <path
                      fill="#CB9A7A"
                      d="M17.632 25.973c1.216 1.374 2.724 1.746 4.364 1.746c1.639 0 3.147-.373 4.363-1.746v-3.491h-8.728v3.491z"
                    />
                    <path
                      fill="#D4AB88"
                      d="M15.445 15.936c0 1.448-.734 2.622-1.639 2.622s-1.639-1.174-1.639-2.622s.734-2.623 1.639-2.623s1.639 1.174 1.639 2.623m16.388 0c0 1.448-.733 2.622-1.639 2.622c-.905 0-1.639-1.174-1.639-2.622s.733-2.623 1.639-2.623s1.639 1.174 1.639 2.623"
                    />
                    <path
                      fill="#D4AB88"
                      d="M13.478 16.96c0-5.589 3.816-10.121 8.523-10.121s8.523 4.532 8.523 10.121s-3.816 10.121-8.523 10.121c-4.707-.001-8.523-4.532-8.523-10.121"
                    />
                    <path
                      fill="#C1694F"
                      d="M22 23.802c-2.754 0-3.6-.705-3.741-.848a.655.655 0 0 1 .902-.95c.052.037.721.487 2.839.487c2.2 0 2.836-.485 2.842-.49a.638.638 0 0 1 .913.015a.669.669 0 0 1-.014.938c-.141.143-.987.848-3.741.848m.75-4.052h-1.5c-.413 0-.75-.337-.75-.75s.337-.75.75-.75h1.5c.413 0 .75.337.75.75s-.337.75-.75.75"
                    />
                    <path
                      fill="#963B22"
                      d="M22 3.48c5.648 0 9.178 4.168 9.178 7.641s-.706 4.863-1.412 3.473l-1.412-2.778s-4.235 0-5.647-1.39c0 0 2.118 4.168-2.118 0c0 0 .706 2.779-3.53-.694c0 0-2.118 1.389-2.824 4.862c-.196.964-1.412 0-1.412-3.473C12.822 7.648 15.646 3.48 22 3.48"
                    />
                    <path
                      fill="#662213"
                      d="M26 17c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1s1 .45 1 1v1c0 .55-.45 1-1 1m-8 0c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1s1 .45 1 1v1c0 .55-.45 1-1 1"
                    />
                    <path
                      fill="#CCD6DC"
                      d="M20.461 36H24l2-4l-3.99-1.867L18 32l2 4z"
                    />
                    <path
                      fill="#9F1D22"
                      d="M22.031 33.957c.744 0 1.246 1.025 1.562 2.043h.549c-.394-1.262-.841-2.438-.841-2.438s.375-.625.531-.906c.184-.33.453-1.57.453-1.57l-2.188-.963c0-.006-.16.006-.16.006l-.184.043l-.172.062c-.217.07.094.008.094.014l-1.973.838s.287 1.24.469 1.57c.156.281.529.906.529.906s-.43 1.106-.797 2.438h.559c.319-1.018.826-2.043 1.569-2.043z"
                    />
                    <path
                      fill="#DD2F45"
                      d="M22.031 33.957c-.744 0-1.25 1.025-1.57 2.043h3.132c-.316-1.018-.818-2.043-1.562-2.043zm-.027-3.144c.391-.023 1.543.771 1.422 1.25c-.461 1.826-.848 1.391-1.391 1.391c-.611 0-.963.473-1.391-1.312c-.091-.388.797-1.298 1.36-1.329"
                    />
                    <path
                      fill="#F4F7F9"
                      d="M26.719 26.75c-.567.566-4.709 3.383-4.709 3.383s2.127 1.242 4.084 3.533c.197.23 1.543-4.625 1.584-5.709c.011-.303-.688-1.478-.959-1.207m-9.418 0c.566.566 4.709 3.383 4.709 3.383s-2.127 1.242-4.084 3.533c-.197.23-1.543-4.625-1.584-5.709c-.012-.303.687-1.478.959-1.207"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="h3_Abt item-title_Abt">
                    Industry Expert Instructor
                  </h3>
                  <p className="item-text_Abt">
                    We offer the best industry expert instructors with
                    real-world experience, providing you with practical
                    knowledge and career-ready skills.
                  </p>
                </div>
              </li>
              <li className="about-item_Abt">
                <div className="item-icon_Abt item-icon_Abt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 64 64"
                  >
                    <linearGradient
                      id="XwHnNngDv7MI2o4BpQr4oa_44781_gr1"
                      x1="27"
                      x2="27"
                      y1="21.75"
                      y2="28.354"
                      gradientUnits="userSpaceOnUse"
                      spreadMethod="reflect"
                    >
                      <stop offset="0" stop-color="#6dc7ff"></stop>
                      <stop offset="1" stop-color="#e6abff"></stop>
                    </linearGradient>
                    <path
                      fill="url(#XwHnNngDv7MI2o4BpQr4oa_44781_gr1)"
                      d="M32,28H22c-0.552,0-1-0.448-1-1v-4c0-0.552,0.448-1,1-1h10c0.552,0,1,0.448,1,1v4 C33,27.552,32.552,28,32,28z"
                    ></path>
                    <linearGradient
                      id="XwHnNngDv7MI2o4BpQr4ob_44781_gr2"
                      x1="32"
                      x2="32"
                      y1="5.375"
                      y2="58.524"
                      gradientUnits="userSpaceOnUse"
                      spreadMethod="reflect"
                    >
                      <stop offset="0" stop-color="#1a6dff"></stop>
                      <stop offset="1" stop-color="#c822ff"></stop>
                    </linearGradient>
                    <path
                      fill="url(#XwHnNngDv7MI2o4BpQr4ob_44781_gr2)"
                      d="M49,6H35.052c-0.019,0-0.036,0-0.054,0c-1.03,0-2.001,0.386-2.752,1.099 C31.454,7.851,31,8.908,31,10H16c-2.757,0-5,2.243-5,5v38c0,2.757,2.243,5,5,5h28c2.757,0,5-2.243,5-5V27.998 c0.618,0.219,1.305,0.236,1.973,0.006C52.204,27.581,53,26.467,53,25.165V10C53,7.794,51.206,6,49,6z M39.027,28.004 c0.33,0.113,0.665,0.168,0.994,0.168c0.902,0,1.764-0.411,2.349-1.163l0.63-0.81V42H17V16h20v9.165 C37,26.467,37.796,27.581,39.027,28.004z M33.623,8.549C33.999,8.192,34.484,8,35,8h0.034c0.025,0,0.05,0.001,0.074,0.003 c0.99,0.052,1.796,0.933,1.876,1.997H33C33,9.446,33.222,8.931,33.623,8.549z M47,53c0,1.654-1.346,3-3,3H16c-1.654,0-3-1.346-3-3 s1.346-3,3-3h28c1.125,0,2.164-0.373,3-1.002V53z M44,48H16c-1.13,0-2.162,0.391-3,1.025V15c0-1.654,1.346-3,3-3h21v2H17 c-1.103,0-2,0.897-2,2v26c0,1.103,0.897,2,2,2h26c1.103,0,2-0.897,2-2V24.276c0.299,0,0.599,0.123,0.79,0.369L47,26.199V45 C47,46.654,45.654,48,44,48z M51,25.165c0,0.664-0.518,0.893-0.677,0.947c-0.159,0.057-0.707,0.192-1.115-0.332l-1.839-2.363 c-0.573-0.737-1.437-1.159-2.369-1.159s-1.796,0.422-2.368,1.158l-1.84,2.364c-0.407,0.522-0.956,0.387-1.115,0.332 S39,25.829,39,25.165V10.17c0-0.794-0.212-1.537-0.581-2.17H49c1.103,0,2,0.897,2,2V25.165z"
                    ></path>
                    <linearGradient
                      id="XwHnNngDv7MI2o4BpQr4oc_44781_gr3"
                      x1="27"
                      x2="27"
                      y1="5.375"
                      y2="58.524"
                      gradientUnits="userSpaceOnUse"
                      spreadMethod="reflect"
                    >
                      <stop offset="0" stop-color="#1a6dff"></stop>
                      <stop offset="1" stop-color="#c822ff"></stop>
                    </linearGradient>
                    <path
                      fill="url(#XwHnNngDv7MI2o4BpQr4oc_44781_gr3)"
                      d="M21 30H33V32H21z"
                    ></path>
                    <linearGradient
                      id="XwHnNngDv7MI2o4BpQr4od_44781_gr4"
                      x1="30"
                      x2="30"
                      y1="5.375"
                      y2="58.524"
                      gradientUnits="userSpaceOnUse"
                      spreadMethod="reflect"
                    >
                      <stop offset="0" stop-color="#1a6dff"></stop>
                      <stop offset="1" stop-color="#c822ff"></stop>
                    </linearGradient>
                    <path
                      fill="url(#XwHnNngDv7MI2o4BpQr4od_44781_gr4)"
                      d="M21 34H39V36H21z"
                    ></path>
                  </svg>
                </div>
                <div>
                  <h3 className="h3_Abt item-title_Abt">
                    Up-to-Date Course Content
                  </h3>
                  <p className="item-text_Abt">
                    Our courses feature up-to-date content, keeping pace with
                    industry trends to ensure you learn the latest tools and
                    techniques.
                  </p>
                </div>
              </li>
              <li className="about-item_Abt">
                <div className="item-icon_Abt item-icon_Abt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 36 36"
                  >
                    <path fill="#55ACEE" d="m18 8l-7-8H0l14 17l11.521-4.75z" />
                    <path
                      fill="#3B88C3"
                      d="m25 0l-7 8l5.39 7.312l1.227-1.489L36 0z"
                    />
                    <path
                      fill="#FFAC33"
                      d="M23.26 16.026c.08-.217.131-.448.131-.693a2 2 0 0 0-2-2h-6.667a2 2 0 0 0-2 2c0 .245.05.476.131.693c-3.258 1.826-5.464 5.307-5.464 9.307C7.391 31.224 12.166 36 18.058 36c5.891 0 10.667-4.776 10.667-10.667c-.001-4-2.206-7.481-5.465-9.307z"
                    />
                    <circle cx="18.058" cy="25.333" r="8" fill="#FFD983" />
                    <path
                      fill="#FFAC33"
                      d="M21.278 30.634a.699.699 0 0 1-.406-.131L18 28.444l-2.871 2.059a.697.697 0 0 1-1.071-.777l1.071-3.456l-2.845-2.005a.698.698 0 0 1 .409-1.259l3.524-.005l1.122-3.37a.697.697 0 0 1 1.324 0l1.103 3.37l3.542.005a.697.697 0 0 1 .409 1.259l-2.846 2.005l1.071 3.456a.695.695 0 0 1-.664.908z"
                    />
                  </svg>{" "}
                </div>
                <div>
                  <h3 className="h3_Abt item-title_Abt">
                    Biggest Student Community
                  </h3>
                  <p className="item-text_Abt">
                    Join the biggest student community, where you can connect,
                    collaborate, and grow with like-minded learners from around
                    the world.
                  </p>
                </div>
              </li>
            </ul>
            <a href="#" className="btn_Abt btn-primary_Abt">
              <span className="span">Know About Us</span>
              <ion-icon name="arrow-forward-outline" aria-hidden="true" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
