import React from "react";
import "./PopularCourse.css";

const Popular = ({ id }) => {
  return (
    <section
      className="section_Pop blog has-bg-image_Pop"
      id={id}
      aria-label="blog"
      // style={{ backgroundImage: 'url("https://raw.githubusercontent.com/codewithsadee/eduweb/e95ce3bd395db274166c4d8abe7970b699a4c39d/assets/images/blog-bg.svg")' }}
    >
      <div className="container_Pop">
        <p className="section-subtitle_pop">Popular IIternship Course</p>
        <h2 className="h2_Pop section-title_Pop">Think Innovative Do Creative</h2>
        <ul className="grid-list_Pop">
          <li>
            <div className="blog-card_Pop">
              <figure className="card-banner_Pop img-holder_Pop has-after_Pop">
                <img
                  src="https://codewithsadee.github.io/eduweb/assets/images/blog-1.jpg"
                  width={370}
                  height={370}
                  loading="lazy"
                  alt="Become A Better Blogger: Content Planning"
                  className="img-cover_Pop"
                />
              </figure>
              <div className="card-content_Pop">
                <a href="#" className="card-btn_Pop" aria-label="read more">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 15 15"
                  >
                    <path
                      fill="currentColor"
                      d="M8.293 2.293a1 1 0 0 1 1.414 0l4.5 4.5a1 1 0 0 1 0 1.414l-4.5 4.5a1 1 0 0 1-1.414-1.414L11 8.5H1.5a1 1 0 0 1 0-2H11L8.293 3.707a1 1 0 0 1 0-1.414Z"
                    />
                  </svg>
                </a>
                {/* <a href="#" className="card-subtitle_Pop">
                  Online
                </a> */}
                <h3 className="h3_Pop">
                  <a href="#" className="card-title_Pop">
                    Become A Better Blogger: Content Planning
                  </a>
                </h3>
                <ul className="card-meta-list_Pop">
                  <li className="card-meta-item_Pop">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M19.5 4h-3V2.5a.5.5 0 0 0-1 0V4h-7V2.5a.5.5 0 0 0-1 0V4h-3A2.503 2.503 0 0 0 2 6.5v13A2.503 2.503 0 0 0 4.5 22h15a2.502 2.502 0 0 0 2.5-2.5v-13A2.502 2.502 0 0 0 19.5 4zM21 19.5a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5V11h18v8.5zm0-9.5H3V6.5C3 5.672 3.67 5 4.5 5h3v1.5a.5.5 0 0 0 1 0V5h7v1.5a.5.5 0 0 0 1 0V5h3A1.5 1.5 0 0 1 21 6.5V10z"
                      />
                    </svg>
                    <span className="span_Pop">Oct 10, 2021</span>
                  </li>
                  <li className="card-meta-item_Pop">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#FFE21F"
                      stroke="#FFE21F"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="m12.495 18.587l4.092 2.15a1.044 1.044 0 0 0 1.514-1.106l-.783-4.552a1.045 1.045 0 0 1 .303-.929l3.31-3.226a1.043 1.043 0 0 0-.575-1.785l-4.572-.657A1.044 1.044 0 0 1 15 7.907l-2.088-4.175a1.044 1.044 0 0 0-1.88 0L8.947 7.907a1.044 1.044 0 0 1-.783.575l-4.51.657a1.044 1.044 0 0 0-.584 1.785l3.309 3.226a1.044 1.044 0 0 1 .303.93l-.783 4.55a1.044 1.044 0 0 0 1.513 1.107l4.093-2.15a1.043 1.043 0 0 1 .991 0"
                    />
                  </svg>
                    <span className="span_Pop">4.5/5</span>
                  </li>
                </ul>
                <p className="card-text_Pop">
                  Lorem Ipsum Dolor Sit Amet Cons Tetur Adipisicing Sed.
                </p>
              </div>
            </div>
          </li>
          <li>
            <div className="blog-card_Pop">
              <figure className="card-banner_Pop img-holder_Pop has-after_Pop">
                <img
                  src="https://codewithsadee.github.io/eduweb/assets/images/blog-1.jpg"
                  width={370}
                  height={370}
                  loading="lazy"
                  alt="Become A Better Blogger: Content Planning"
                  className="img-cover_Pop"
                />
              </figure>
              <div className="card-content_Pop">
                <a href="#" className="card-btn_Pop" aria-label="read more">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 15 15"
                  >
                    <path
                      fill="currentColor"
                      d="M8.293 2.293a1 1 0 0 1 1.414 0l4.5 4.5a1 1 0 0 1 0 1.414l-4.5 4.5a1 1 0 0 1-1.414-1.414L11 8.5H1.5a1 1 0 0 1 0-2H11L8.293 3.707a1 1 0 0 1 0-1.414Z"
                    />
                  </svg>
                </a>
                {/* <a href="#" className="card-subtitle_Pop">
                  Online
                </a> */}
                <h3 className="h3_Pop">
                  <a href="#" className="card-title_Pop">
                    Become A Better Blogger: Content Planning
                  </a>
                </h3>
                <ul className="card-meta-list_Pop">
                  <li className="card-meta-item_Pop">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M19.5 4h-3V2.5a.5.5 0 0 0-1 0V4h-7V2.5a.5.5 0 0 0-1 0V4h-3A2.503 2.503 0 0 0 2 6.5v13A2.503 2.503 0 0 0 4.5 22h15a2.502 2.502 0 0 0 2.5-2.5v-13A2.502 2.502 0 0 0 19.5 4zM21 19.5a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5V11h18v8.5zm0-9.5H3V6.5C3 5.672 3.67 5 4.5 5h3v1.5a.5.5 0 0 0 1 0V5h7v1.5a.5.5 0 0 0 1 0V5h3A1.5 1.5 0 0 1 21 6.5V10z"
                      />
                    </svg>
                    <span className="span_Pop">Oct 10, 2021</span>
                  </li>
                  <li className="card-meta-item_Pop">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#FFE21F"
                      stroke="#FFE21F"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="m12.495 18.587l4.092 2.15a1.044 1.044 0 0 0 1.514-1.106l-.783-4.552a1.045 1.045 0 0 1 .303-.929l3.31-3.226a1.043 1.043 0 0 0-.575-1.785l-4.572-.657A1.044 1.044 0 0 1 15 7.907l-2.088-4.175a1.044 1.044 0 0 0-1.88 0L8.947 7.907a1.044 1.044 0 0 1-.783.575l-4.51.657a1.044 1.044 0 0 0-.584 1.785l3.309 3.226a1.044 1.044 0 0 1 .303.93l-.783 4.55a1.044 1.044 0 0 0 1.513 1.107l4.093-2.15a1.043 1.043 0 0 1 .991 0"
                    />
                  </svg>
                    <span className="span_Pop">4.5/5</span>
                  </li>
                </ul>
                <p className="card-text_Pop">
                  Lorem Ipsum Dolor Sit Amet Cons Tetur Adipisicing Sed.
                </p>
              </div>
            </div>
          </li>
          <li>
            <div className="blog-card_Pop">
              <figure className="card-banner_Pop img-holder_Pop has-after_Pop">
                <img
                  src="https://codewithsadee.github.io/eduweb/assets/images/blog-1.jpg"
                  width={370}
                  height={370}
                  loading="lazy"
                  alt="Become A Better Blogger: Content Planning"
                  className="img-cover_Pop"
                />
              </figure>
              <div className="card-content_Pop">
                <a href="#" className="card-btn_Pop" aria-label="read more">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 15 15"
                  >
                    <path
                      fill="currentColor"
                      d="M8.293 2.293a1 1 0 0 1 1.414 0l4.5 4.5a1 1 0 0 1 0 1.414l-4.5 4.5a1 1 0 0 1-1.414-1.414L11 8.5H1.5a1 1 0 0 1 0-2H11L8.293 3.707a1 1 0 0 1 0-1.414Z"
                    />
                  </svg>
                </a>
                {/* <a href="#" className="card-subtitle_Pop">
                  Online
                </a> */}
                <h3 className="h3_Pop">
                  <a href="#" className="card-title_Pop">
                    Become A Better Blogger: Content Planning
                  </a>
                </h3>
                <ul className="card-meta-list_Pop">
                  <li className="card-meta-item_Pop">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M19.5 4h-3V2.5a.5.5 0 0 0-1 0V4h-7V2.5a.5.5 0 0 0-1 0V4h-3A2.503 2.503 0 0 0 2 6.5v13A2.503 2.503 0 0 0 4.5 22h15a2.502 2.502 0 0 0 2.5-2.5v-13A2.502 2.502 0 0 0 19.5 4zM21 19.5a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5V11h18v8.5zm0-9.5H3V6.5C3 5.672 3.67 5 4.5 5h3v1.5a.5.5 0 0 0 1 0V5h7v1.5a.5.5 0 0 0 1 0V5h3A1.5 1.5 0 0 1 21 6.5V10z"
                      />
                    </svg>
                    <span className="span_Pop">Oct 10, 2021</span>
                  </li>
                  <li className="card-meta-item_Pop">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#FFE21F"
                      stroke="#FFE21F"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="m12.495 18.587l4.092 2.15a1.044 1.044 0 0 0 1.514-1.106l-.783-4.552a1.045 1.045 0 0 1 .303-.929l3.31-3.226a1.043 1.043 0 0 0-.575-1.785l-4.572-.657A1.044 1.044 0 0 1 15 7.907l-2.088-4.175a1.044 1.044 0 0 0-1.88 0L8.947 7.907a1.044 1.044 0 0 1-.783.575l-4.51.657a1.044 1.044 0 0 0-.584 1.785l3.309 3.226a1.044 1.044 0 0 1 .303.93l-.783 4.55a1.044 1.044 0 0 0 1.513 1.107l4.093-2.15a1.043 1.043 0 0 1 .991 0"
                    />
                  </svg>
                    <span className="span_Pop">4.5/5</span>
                  </li>
                </ul>
                <p className="card-text_Pop">
                  Lorem Ipsum Dolor Sit Amet Cons Tetur Adipisicing Sed.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Popular;
